@font-face {
  font-family: 'Baskerville';
  src: local('MyFont'), url(./fonts/LibreBaskerville-Regular.ttf) format('woff');
}
.App {
  text-align: center;
    color: #800000;
    font-family: Baskerville;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#tel{
  border: none;
}
input{
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 4px;
  color: #800000;
  text-align: center;
}
#tel:hover, #tel:active,#code:hover, #code:active{
  border: 1px solid #800000;

}
#tel:focus, #code:focus {
        box-shadow: 0 0 3pt 2pt #800000;
    }
.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #800000;
}

.App-link {
  color: #d4af37ff;
  cursor: pointer;
}
.triples{
  width: 30vw;
  display: inline-block;
  float:left;
}
.texto{
  display: block;
}
#supervivencia{
clear:left;
}
#resultados{
  display: block;
  width: 75%;
  margin: auto;
}
.inputDiv{
  width: 100vw;
  display: block;
  height: 180vh;
  margin:auto;
}
.recarga{
  margin: auto;
  display:block;
}
.Contenido{
  margin-top: 10vh;
  margin-left: 3vw;
  margin-right: 3vw;
  height: 90vh;
}
